@media (pointer:coarse) {
	.claim-gift .container {
		display: flex;
		height: -webkit-fill-available;
		flex-direction: column;
		justify-content: space-between;
		padding: .8rem;
	}
}

@media (pointer:fine) {
	.claim-gift .container {
		display: flex;
		height: 100vh;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 .8rem;
	}
}

.brand-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 6em;
}

.logo {
	max-width: 45%;
	top: 50%;
	height: 100%;

}

.brand-logo {
	max-width: 100%;
	height: 100%;
	object-fit: contain;

}

.brand-name {
	position: relative;
	margin-left: 0.5em;
	top: 40%;
	font-weight: 600;
	font-size: 14pt;
}

.gift-name-text {
	font-weight: 500;
	color: #de545c;
}

input[type=submit] {
	background: #de545c;
}

.claim-gift-footer {
	text-align: center;
	width: 100%;
}

.brand-name-text {
	font-weight: 500;
}

.warning-text {
	font-style: italic;
	font-size: small;
}