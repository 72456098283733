:root {
    --wheel-font: 'Montserrat', 'Montserrat', sans-serif;
    --wheel-text-size: 12px;
    --wheel-size: 270px;
    --wheel-slice-spacing: 25px;
    --wheel-border-size: 10px;
    --wheel-color: white;
    --wheel-color: #6A7C55;
    --wheel-text-color: white;
    --wheel-center-logo: "";
    --neutral-color: white;
    --wheel-item-color: #FFFFFF19;
    --PI: 3.14159265358979;
    --nb-item: 0;
    --item-nb: 0;
    --selected-item: 0;
    --nb-turn: 5;
    --spinning-duration: 4s;
    --reset-duration: 0.25s;
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
    display: block;
    position: relative;
    box-sizing: content-box;
    width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
    padding: 3px;
    margin: 2rem auto;
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, .5);
    background-color: var(--wheel-color);
    transform: rotate(-90deg);
    border-radius: 50%;
    user-select: none;
}

@media screen and (max-height: 700px) {
    .wheel-container {
        margin: auto;
    }
}

.wheel-selector {
    position: absolute;
    top: 50%;
    z-index: 10;
    right: 0;
    height: 64px;
    width: 48px;
    background-color: black;
    bottom: 0;
    transform: translate(0, -50%) rotate(90deg);
    clip-path: polygon(50% 50%, 0 0, 100% 0);
    border-radius: 5px;
    overflow: hidden;
}
.wheel-selector .inside {
    position: absolute;
    height: 40px;
    width: 32px;
    background: white;
    transform: translate(8px, 4px);
    clip-path: polygon(50% 50%, 0 0, 100% 0);
    border-radius: 2px;
}

/* Roue */
.wheel {
    display: block;
    position: relative;
    box-sizing: content-box;
    margin: auto;
    width: var(--wheel-size);
    height: var(--wheel-size);
    overflow: hidden;
    border-radius: 50%;
    border: solid var(--wheel-color) var(--wheel-border-size);
    background-color: var(--wheel-color);
    transition: transform var(--reset-duration);
    transform: rotate(0deg);
    cursor: pointer;
}

.wheel.spinning {
    transition: transform var(--spinning-duration);
    transform: rotate(calc(var(--nb-turn) * 360deg + (-360deg * var(--selected-item) / var(--nb-item, 1))));
}


/* Centre de la roue = rond blanc au centre */
.wheel-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: var(--wheel-color);
    width: 5rem;
    height: 5rem;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    border-radius: 50%;
}

.wheel-logo img{
    max-width: 4rem;
    max-height: 4rem;;
}

/* Element sur la roue */
.wheel-item {
    display: block;
    position: absolute;
    box-sizing: border-box;
    /* position de l'item */
    top: 50%;
    left: 50%;
    width: 50%;
    transform-origin: center left;
    transform: translateY(-50%) rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));
    /* texte */
    text-align: right;
    padding: 0 25px 0 50px;

}

.wheel-item svg{
    transform: rotate(-92.6deg) translateX(-10px) scale(2.75);
    font-weight: 600;
    font-family: 'Proxima nova';
    text-transform: uppercase;
    font-size: 24px;
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0px;
    --slice-max-width: calc(var(--PI) * var(--wheel-size) + var(--wheel-size) / 2);
    --slice-width: calc((var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing));
    border: solid transparent calc(var(--slice-width) / 2);
    border-left: solid transparent 0;
    /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
    border-right: solid var(--wheel-item-color) calc(var(--wheel-size) / 2);
}