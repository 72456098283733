#game-board {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  position: relative;
  justify-content: center;
}
#game-board .card {
  width: 24vw;
  height: 15vh;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  margin: 8px;
  transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  perspective: 1000;
  transform: perspective(1000px);
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
}
#game-board .card.shuffle {
  animation: shuffleAnimation 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-direction: alternate;
}
@keyframes shuffleAnimation {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  20% {
    transform: translate(-5%, -5%) rotate(-10deg);
  }
  40% {
    transform: translate(5%, -10%) rotate(10deg);
  }
  60% {
    transform: translate(-10%, -15%) rotate(-10deg);
  }
  80% {
    transform: translate(10%, -20%) rotate(10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0);
  }
}
#game-board .card div {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  transition: 0.6s;
  background: #fff;
}
#game-board .card .back {
  font-size: 50px;
  line-height: 20px;
  cursor: pointer;
  color: #6d1124;
  display: flex;
  align-items: center;
  justify-content: center;
}
#game-board .card .back img {
  vertical-align: middle;
  max-width: 32px;
  max-height: 32px;
}
#game-board .card .front {
  transform: rotateY(180deg);
  font-size: 7px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem .5rem;
  justify-content: space-between;
  text-transform: uppercase;
  -webkit-text-emphasis: none;
  text-emphasis: none;
}
#game-board .card .front img {
  vertical-align: middle;
  max-width: 48px;
  max-height: 48px;
  margin-top: 14px;
  margin-bottom: 14px;
}
#game-board .card.flipped {
  transform: rotateY(180deg);
}

.restart-button {
  width: 12em;
  height: 3em;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 12px;
  background-color: #6d1124;
  border: 0;
}

.centered {
  width: 100%;
  height: 100%;
  text-align: center;
}

@keyframes selected {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}
