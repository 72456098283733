/* latin */
@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC73w5aXx-p7K4KLg.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC73w5aXx-p7K4KLg.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Proxima nova';
  src: url('https://uploads-ssl.webflow.com/634d1ce4d14ffb6934414e3d/634d1ce4d14ffbf2cc414e48_Proxima%20Nova%20Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima nova';
  src: url('https://uploads-ssl.webflow.com/634d1ce4d14ffb6934414e3d/634d1ce4d14ffb4146414e49_Proxima%20Nova%20Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima nova semi';
  src: url('https://uploads-ssl.webflow.com/634d1ce4d14ffb6934414e3d/634d1ce4d14ffb49ef414e4b_Proxima%20Nova%20Semi%20Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima nova bold';
  src: url('https://uploads-ssl.webflow.com/634d1ce4d14ffb6934414e3d/634d1ce4d14ffb0521414e4c_Proxima%20Nova%20Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.upperContainer {
  background: #fff6e5;
  height: -webkit-fill-available;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
}

.centerLogo {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  z-index: 9;
  color: #d64c4c;
  margin-top: 20px;
}

.brand-logo {
  max-height: 120px;
}

.titleShop {
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: 900;
  font-size: 16pt;
  text-align: center;
  position: relative;
  z-index: 9;
  color: #d64c4c;
  margin-top: 15px;
  background: white;
  padding: 2px 2px;
  border: 3px solid #d64c4c;
  box-shadow: 4px 4px 0 #ffd4d4;
}

.triangle {
  border-left: 90px solid transparent;
  border-right: 90px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 0;
  width: 100px;
}

.roue {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem auto;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
}

.clickToShuffle {
  margin: 0 auto;
  display: block;
  width: 70%;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--wheel-button-color);
  font-family: 'Proxima nova';
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.clickToShuffle:disabled {
  background: var(--wheel-button-color);
}

@keyframes opacity {
  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.clickToTurnWheel {
  margin: 0 auto;
  display: block;
  width: 70%;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: var(--wheel-button-color);
  font-family: 'Proxima nova';
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
}

.clickToTurnWheel:disabled {
  background: var(--wheel-button-color);
}


.preTitle {
  padding: 0 32px;
  margin-top: 3rem;
  margin-bottom: 25px;
  text-align: center;
  position: relative;
  letter-spacing: 1px;
  z-index: 9;
  line-height: 1.3;
}

.preTitle h1 {
  text-transform: uppercase;
  font-family: 'Proxima nova';
  font-weight: 900;
  font-size: 20px;
}

.preTitle p {
  text-transform: none;
  font-family: 'Proxima nova';
  font-weight: 500;
  font-size: 16px;
  color: inherit;
  margin: 0;
  margin-top: 8px;
}

.socle {
  position: absolute;
  height: calc(130px);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.socle svg {
  object-position: top center;
  object-fit: contain;
  height: 100%;
  margin: auto;
  display: block;
}

.text {
  position: absolute;
}

.custom-form {
  margin: 0 0 12px 0;
  padding: 15px 10px;
  width: 100%;
  outline: none;
  border: 1px solid #bbb;
  border-radius: 4px;
  display: inline-block;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.generic-cta {
  padding: 15px 50px !important;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  margin-top: 10px;
  border: none;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: Montserrat-Bold;
  text-transform: uppercase;

  -webkit-transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -ms-transition: 0.2s ease all;
  -o-transition: 0.2s ease all;
  transition: 0.2s ease all;
}

.generic-cta:hover {
  opacity: 0.8;
}

.generic-cta:active {
  opacity: 0.4;
}

.generic-cta:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.title-modal {
  text-transform: uppercase;
  font-family: 'Proxima nova';
  font-weight: 900;
  margin: 30px 20px 20px 20px;
  letter-spacing: 0px;

  font-size: 20px;
  text-align: center;
  position: relative;
  z-index: 9;
  /* text-shadow: 3px 3px 0 #ccc6df; */
}

.title-modal-alt {
  text-transform: uppercase;
  font-family: 'Proxima nova';
  font-weight: 900;
  letter-spacing: 0px;

  font-size: 18pt;
  text-align: center;
  position: relative;
  z-index: 9;
  color: #d64c4c;
  /* text-shadow: 3px 3px 0 #ccc6df; */
}

.form-text {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  position: relative;
  color: #d64c4c;
  cursor: initial;
  margin-top: 10px;
}

.form-box {
  font-family: Montserrat;
  font-size: 11pt;
}

.submit-button {}

.core-text {
  font-family: Montserrat;
  text-align: center;
  font-size: 16px;
}

.subtitle-modal {
  font-family: 'Proxima nova';
  margin: 0px 16px 2.5rem 16px;
  padding: 0 24px;
  font-size: 16px;
  text-align: center;
  position: relative;
  z-index: 9;
}

.footer,
.push {
  height: 30px;
  width: 100%;
  bottom: 0;
  z-index: 9;
  background-color: transparent;
  position: fixed;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.marketing {
  display: flex;
}

.marketing label {
  margin-left: 24px;
  font-size: 12px;
}

/* Please ❤ this if you like it! */

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900');

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.7;
  color: #1f2029;
  background-color: #fff;
  overflow: scroll;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 101%;
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.7;
  color: #1f2029;
}

.section {
  position: relative;
  width: 100%;
  display: block;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
}

.full-height {
  min-height: 100vh;
}

[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  accent-color: #df545d;
  position: absolute;
}

.opened-modal+label,
.modal-btn:not(:checked)+label {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  height: 50px;
  transition: all 200ms linear;
  border-radius: 4px;
  width: 240px;
  letter-spacing: 1px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  -ms-flex-item-align: center;
  align-self: center;
  border: none;
  cursor: pointer;
  background-color: #102770;
  color: #ffeba7;
  box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.25);
}

.modal-btn:not(:checked)+label:hover {
  background-color: #ffeba7;
  color: #102770;
}

.opened-modal+label .uil,
.modal-btn:not(:checked)+label .uil {
  margin-left: 10px;
  font-size: 18px;
}

.opened-modal+label:after,
.modal-btn:not(:checked)+label:after {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 110;
  width: 40px;
  border-radius: 3px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  background-color: #ffeba7;
  color: #102770;
  font-family: 'unicons';
  content: '\eac6';
  box-shadow: 0 12px 25px 0 rgba(16, 39, 112, 0.25);
  transition: all 200ms linear;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
}

.opened-modal+label:hover:after,
.modal-btn:not(:checked)+label:hover:after {
  background-color: #102770;
  color: #ffeba7;
}

.opened-modal+label:after {
  transition: opacity 300ms 300ms ease, transform 300ms 300ms ease,
    background-color 250ms linear, color 250ms linear;
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  background-color: rgba(30, 30, 50, 0.8);
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms 700ms ease;
}

.opened-modal .modal {
  pointer-events: auto;
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.modal-wrap {
  position: relative;
  display: block;
  min-width: 100%;
  max-height: 65vh;
  height: 100%;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  padding-bottom: 20px;
  background-color: #fff;
  -ms-flex-item-align: center;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.2);
  opacity: 0;
  transform: translate3d(0, 1500px, 0);
  transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
}

@media screen and (max-height: 700px) {
  .modal-wrap {
    max-height: 70vh;
  }

  .core-text {
    font-size: 14px;
  }
}

.modal-wrap p {
  padding: 15px 30px 0 30px;
}

.opened-modal .modal .modal-wrap {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 250ms 250ms ease, transform 300ms 500ms ease;
}

@media screen and (max-width: 500px) {
  .modal-wrap {
    width: calc(100% - 40px);
    padding-bottom: 15px;
  }

  .modal-wrap p {
    padding: 15px 20px 0 20px;
  }
}

button[type='submit'] {
  background: #df545d;
}

.list-icon {
  margin: 3px 0 0 10px;
  width: 20px;
  position: fixed;
}

.stars-icon {
  height: 2rem;
  margin-left: 0.5rem;
  transform: translateY(10px);
}

.subscribe-icon {
  height: 33px;
  position: fixed;
  margin: -2px 0 0 2px;
}

.gift-icon {
  width: 24px;
  position: fixed;
  margin: -3px 0 0 5px;
}

.list-icon.big {
  width: 40px;
}

form input[type='text']:focus,
form input[type='password']:focus {
  border-color: cornflowerblue;
}

.first-modal-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mail-form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

ol {
  list-style-type: none;
  counter-reset: ordered;
}

li.indexed {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  width: 100%;
}

ol.number-list {
  counter-reset: counter-list;
  padding-left: 1rem;
}

ol.number-list>li {
  counter-increment: counter-list;
  margin-left: 0;
}

li.indexed::before {
  border-radius: 2.5rem;
  content: '';
  height: 3rem;
  width: 3rem;
  margin-top: -8px;
  margin-right: 0.25rem;
}

.first-list li::before {
  left: 1rem;
}

li.indexed::before {
  align-items: flex-start;
  content: counter(counter-list);
  display: flex;
  font: 900 2.5rem/1 'Montserrat';
  justify-content: flex-start;
  padding: 8px 0px 0px 8px;
  z-index: 1;
}

.google-button {
  margin: auto auto 1rem auto;
  width: 90%;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: #fff;
}

.google-button img {
  height: 38px;
  margin: 6px;
}

.google-button:hover {
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
}

.google-button:active {
  background: #a83d43;
}

.google-button__text {
  padding: 6px;
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Proxima nova';
  font-weight: 900;
  letter-spacing: 2px;
  white-space: nowrap;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem auto 1rem auto;
}

form {
  padding: 0 20px;
}

form label {
  font-size: 14px;
  color: #211532;
  cursor: pointer;
}

form label,
form input[type='text'] {
  float: left;
  clear: both;
}

input[type='submit'],
input[type='button'] {
  background: #df545d;
}

form input[type='text']:focus,
form input[type='password']:focus {
  border-color: cornflowerblue;
}